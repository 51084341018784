import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Features from "../components/Features";
import Pricing from "../components/Pricing";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import InBlog from "../components/InBlog";
import ChatButton from "../components/ChatButton";

function Home() {
	
	
	
  return (
    <div className="">
      <Navbar />
      <Hero />
      <Features />
      <Pricing />
      <InBlog />
      <Faq />
      <div className="container mx-auto my-10">
      <ChatButton/>
      </div>
      
      <Footer />
    </div>
  );
}

export default Home;
