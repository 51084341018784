import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Support from "./pages/Support";
import Pricing from "./pages/Pricing";
import Features from "./pages/Features";
import Privacy from "./pages/Privacy";
import ChangeLog from "./pages/ChangeLog";
import Terms from "./pages/Terms";
import BlogPost from "./pages/BlogPost";
import SupportPost from "./pages/SupportPost";
import NotFound from "./pages/404";

function App() {
	
	
	
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/help" element={<Support />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/features" element={<Features />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/changelog" element={<ChangeLog />} />
        <Route path="/blog/:parama" element={<BlogPost />} />
         <Route path="/help/:parama" element={<SupportPost />} />
        {/* Add more routes if needed */}
        
        {/* 404 Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
