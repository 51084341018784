// src/pages/Privacy.js
import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ReactMarkdown from "react-markdown";

function Privacy() {
  // Privacy policy content in markdown
  const markdownContent = `
# Terms and Conditions

*Last Updated: November 10, 2024*

Welcome to SEOTIC! By accessing or using our services, you agree to comply with these Terms and Conditions. If you do not agree, please refrain from using SEOTIC.

## Acceptance of Terms

By creating an account or using SEOTIC, you accept these Terms and Conditions. SEOTIC reserves the right to update these Terms at any time, and we will notify users via email of significant changes.

## Description of Services

SEOTIC provides SEO analysis and tracking tools to support your online marketing and optimization efforts. We offer a range of features and services that may evolve or change over time.

## Eligibility

You must be at least 18 years old to use SEOTIC. By accessing our services, you confirm that you meet this age requirement.

## User Responsibilities

As a user of SEOTIC, you agree to:

- **Provide Accurate Information**: Ensure that all information you provide, including name, email, and website address, is accurate and up-to-date.
- **Use SEOTIC for Lawful Purposes**: Do not use SEOTIC for any illegal activities or purposes that violate applicable laws or these Terms.
- **Respect Intellectual Property**: Do not copy, modify, distribute, or sell any part of SEOTIC without our written permission.
- **Maintain Account Security**: You are responsible for the security of your account credentials. Please notify us immediately if you suspect unauthorized access to your account.

## Payment and Billing

SEOTIC offers paid subscription plans. By selecting a subscription, you authorize us to charge your designated payment method through **Stripe**. Subscription fees are non-refundable except as required by law. You may cancel your subscription at any time, but you will not receive a refund for unused portions of your subscription period.

## Data Privacy

We are committed to protecting your data. Please review our [Privacy Policy](/privacy) for information on how we collect, use, and safeguard your information.

## License and Restrictions

SEOTIC grants you a limited, non-exclusive, non-transferable license to use the platform and its features. You agree not to:

- Modify, distribute, or create derivative works of SEOTIC’s materials.
- Reverse engineer or attempt to extract SEOTIC’s source code.
- Use SEOTIC’s tools or data for commercial purposes beyond your personal or business analysis needs.

## Limitation of Liability

To the maximum extent permitted by law, SEOTIC and its affiliates are not liable for any indirect, incidental, special, or consequential damages arising out of or related to your use of SEOTIC. This includes any damages for loss of profits, data, or other intangibles.

## Disclaimer of Warranties

SEOTIC is provided “as is” without warranties of any kind. We do not guarantee uninterrupted or error-free operation, nor do we make any guarantees regarding the accuracy or completeness of the SEO data or analysis provided.

## Termination

We reserve the right to suspend or terminate your account if you violate these Terms or engage in conduct that we determine may harm SEOTIC or other users. You may also terminate your account at any time by contacting us.

## Contact Information

If you have questions about these Terms and Conditions, please contact us at **protect@seotic.co**
  `;

  return (
    <>
      <Navbar />
      <div className="container mx-auto py-12 px-4 lg:px-0">
        <div className="prose prose-lg mx-auto max-w-3xl">
          <ReactMarkdown>{markdownContent}</ReactMarkdown>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Privacy;
