import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ChatButton from '../components/ChatButton';
import InProgress from '../components/InProgress';

function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('en-GB', { month: 'short' });
    const year = date.getFullYear().toString().slice(-2);
    return `${day} ${month}, ${year}`;
  };

  const calculateReadingTime = (content) => {
    const wordsPerMinute = 200;
    const wordCount = content.split(" ").length;
    return Math.ceil(wordCount / wordsPerMinute);
  };

  useEffect(() => {
	   const timestamp = new Date().getTime();
    fetch(`https://www.pixelliongroup.com/seotic/getSupport.php?_=${timestamp}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setBlogs(data);
        } else {
          throw new Error("Expected an array but received something else");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching blogs:", error);
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredBlogs = blogs.filter((post) =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Group blogs by category
  const groupedBlogs = filteredBlogs.reduce((acc, post) => {
    if (!acc[post.category]) {
      acc[post.category] = [];
    }
    acc[post.category].push(post);
    return acc;
  }, {});

  if (loading) {
    return (
      <>
        <Navbar />
        <div className="bg-slate-100 h-full">
          <div className="container mx-auto py-12">
            <div className="text-center"><i className="bx bx-circle bx-burst bx-rotate-270 bx-fw"></i>Loading...</div>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Navbar />
      <div className="bg-slate-100 h-full">
        <div className="container mx-auto py-12 px-4 md:px-0">
        <InProgress />
          <h2 className="text-4xl w-full text-center text-slate-800 mt-12 mb-5 uppercase font-medium">
            Help Center
          </h2>
          <p className="text-center text-slate-600 max-w-2xl mx-auto mb-8">
            Discover actionable strategies and in-depth guides to help you excel in your digital journey.
          </p>

          {/* Search Bar */}
          <div className="mb-12 flex justify-center">
            <input
              type="text"
              placeholder="Search by title, category, or description..."
              value={searchTerm}
              onChange={handleSearch}
              className="px-4 py-2 border border-slate-200 rounded-lg w-full max-w-md focus:outline-none"
            />
          </div>

          {/* Grouped Blog Posts by Category */}
          {Object.keys(groupedBlogs).map((category) => (
            <div key={category} className="mb-12">
              <h3 className="text-2xl font-medium text-slate-800 mb-6">
                {category}
              </h3>
              <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                {groupedBlogs[category].map((post) => (
                  <Link
                    key={post.parama}
                    to={`/help/${post.parama}`}
                    className="bg-white rounded overflow-hidden border border-slate-200"
                  >
                    <div className="px-10 py-5">
                     <h2 className="text-xl font-medium">{post.title}</h2>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ))}
		
          {/* No Results Message */}
          {filteredBlogs.length === 0 && (
            <div className="text-center text-slate-500 mt-8 mb-8">
              No blog posts found for "{searchTerm}"
            </div>
          )}
          
             <ChatButton/>
        </div>
        
      </div>
      
      <Footer />
    </>
  );
}

export default Blog;
