// src/pages/Privacy.js
import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ReactMarkdown from "react-markdown";

function Privacy() {
  // Privacy policy content in markdown
  const markdownContent = `
# Privacy Policy

*Last Updated: November 10, 2024*

At SEOTIC, your privacy is our priority. This Privacy Policy explains how we collect, use, and protect your personal information when you use our SAAS SEO tools. By using SEOTIC, you consent to the practices described here.

## Information We Collect

To deliver an efficient SEO service, we collect only the essential personal information required to operate our platform:

- **Personal Information**: We collect your name and email address to create and manage your account.
- **Website Information**: We collect the website address for analysis and tracking purposes.

## How We Use Your Information

The information we collect is used to enhance your experience and ensure smooth functionality across our services:

- **Account Management**: To manage your SEOTIC account and personalize your experience.
- **Customer Support**: To address any inquiries or support requests.
- **News, Blog, and Updates**: To keep you informed with newsletters, product updates, blog content, and other relevant information. You may choose to unsubscribe from these communications at any time.

## Information Sharing with Third Parties

SEOTIC is committed to safeguarding your privacy and only shares information with trusted third-party services that are essential to our operations and marketing efforts. The third parties we engage with are:

- **Stripe**: For secure payment processing, your information is shared with Stripe, a trusted payment provider that adheres to industry-leading security standards.
- **Google Analytics**: We use Google Analytics to monitor website traffic and user interaction, helping us improve SEOTIC’s performance and usability.
- **Google AdWords**: For targeted pay-per-click (PPC) campaigns, we use Google AdWords to reach potential users and provide relevant content.
- **Mailer Lite**: To manage our email communications and send you updates, newsletters, and product information, we rely on Mailer Lite, our email service provider.
- **Facebook**: For targeted PPC campaigns, we use Facebook advertising to reach and engage relevant audiences.

Each of these third-party services adheres to stringent data protection and privacy standards. Your information is only shared with them to the extent necessary for SEOTIC’s operation and functionality.

## Data Storage and Security

Your information is stored securely within our database, protected by industry-standard security protocols to prevent unauthorized access, alteration, disclosure, or destruction.

## User Rights and Choices

We respect your control over your personal information. You have the following options regarding your data:

- **Data Deletion**: You may request data deletion at any time by contacting us. Upon confirmation, your data will be permanently removed from our system.
- **Data Export**: Currently, we do not offer the ability to export your data from SEOTIC.

## Age Restrictions

SEOTIC is designed for users who are 18 years of age or older. By accessing our platform, you confirm that you meet this age requirement.

## Changes to the Privacy Policy

We may update this Privacy Policy periodically to reflect changes in our practices, services, or regulatory requirements. In the event of significant changes, we will notify you via email.

## Contact Us

If you have questions, concerns, or requests regarding this Privacy Policy or your data, please contact us at **protect@seotic.co**
  `;

  return (
    <>
      <Navbar />
      <div className="container mx-auto py-12 px-4 lg:px-0">
        <div className="prose prose-lg mx-auto max-w-3xl">
          <ReactMarkdown>{markdownContent}</ReactMarkdown>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Privacy;
