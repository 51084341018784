import React from "react";
import Navbar from "../components/Navbar";


import Pricing from "../components/Pricing";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import ChatButton from "../components/ChatButton";

function PricingPage() {
  return (
    <div className="">
      <Navbar />


      <Pricing />
      <ChatButton />
      <Faq />
      <Footer />
    </div>
  );
}

export default PricingPage;
